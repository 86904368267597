import React from "react";
import Img from "gatsby-image";
import { FoundationPeopleSelectorDiv } from "./elements";
import { H5Strong, H6, H4BigStrong } from "./../Typography";

const FoundationPeopleSelector = ({ people, topColor, bottomColor }) => {
  const [currentPerson, setCurrentPerson] = React.useState(0);
  // console.log(people.map((x) => x.image));
  return people.length ? (
    <FoundationPeopleSelectorDiv
      topColor={bottomColor || "var(--pink)"}
      bottomColor={bottomColor || "var(--white)"}
    >
      <div>
        <div className="slider">
          {people.map((person, index) => (
            <div
              key={index}
              onClick={() => {
                setCurrentPerson(index);
              }}
              className={index === currentPerson ? "selected" : ""}
            >
              <div>
                {person.image ? (
                  <Img fixed={person.image.childImageSharp.fixed} />
                ) : (
                  <div className="placeholder" />
                )}
              </div>
              <H5Strong>{person.name}</H5Strong>
              <H6>{person.title}</H6>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div>
          <H4BigStrong>{people[currentPerson].name}</H4BigStrong>
          <div
            dangerouslySetInnerHTML={{
              __html: people[currentPerson].bio,
            }}
          />
        </div>
      </div>
    </FoundationPeopleSelectorDiv>
  ) : null;
};

export default FoundationPeopleSelector;
