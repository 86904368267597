import styled from "styled-components";

export const TabSetDiv = styled.div`
  --accentColor: ${(props) => props.color || "var(--purple)"};
  width: 100%;
  & > nav {
    display: flex;
    justify-content: center;
    padding-top: 35px;
    padding-bottom: 25px;
    & ul {
      list-style-type: none;
      display: inline-flex;
      margin: 0;
      padding: 0;
      user-select: none;
      & li {
        display: inline-flex;
        background-color: var(--light);
        transition: 0.5s;
        user-select: none;
        &.selected {
          background-color: var(--accentColor);
          & a {
            color: var(--white);
          }
        }
        & + li {
          margin-left: 3px;
        }
        & a {
          padding: 5px 12px;

          width: 100%;
          height: 100%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: var(--black);
        }
      }
    }
  }
  .pdf_btn_selector {  
      position: relative;
      max-width: 823px;
      margin: 0 auto;
      top: 0px;
      left: -40px;
      text-align:center;
  }
  .pdf_btn_selector ~ div{
    margin-top: -30px !important;
  } 
  .pdf_btn_selector ~ div .elements__RichTextDiv-ddzFJn.jEqOBI{
    margin-top: -70px !important; 
  }
  .pdf_btn_selector ~ div .elements__RichContextWrapper-sc-14qgf1b-0.rODmV{
    margin-top: -70px !important;
  }
  @media (max-width: 767px) {
    & > nav > ul {
      max-width: 100vw;
      overflow-x: scroll;
      & > li {
        white-space: nowrap;
      }
    }
    .pdf_btn_selector{
      top:30px;
      left: 10px;
    }
    .pdf_btn_selector ~ div{
      margin-top: 0px !important;
    } 
    .pdf_btn_selector ~ div .elements__RichTextDiv-ddzFJn.jEqOBI{
      margin-top: 0px !important;
    }
    .pdf_btn_selector ~ div .elements__RichContextWrapper-sc-14qgf1b-0.rODmV{
    margin-top: 0px !important;
  }
  }
  @media only screen and (min-width:1400px){
    .pdf_btn_selector{
       max-width: 1183px;
     }
  }
`;
