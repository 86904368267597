import React from "react";
import PropTypes from "prop-types";
import { TabSetDiv } from "./elements";
import { useLocation, navigate } from "@reach/router";
import { H6 } from "./../Typography";

/**
 * Tab content is passed as an array, `tabs`, which contains a `title`, `slug` (optional) and `content`. `content` is a JSX element.
 *
 * `color` can be anything; the default is `var(--green)`. Set it to `var(--red)` for events.
 */
const Selector = ({ tabs, color,leftColumn }) => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = React.useState(0);

  React.useEffect(() => {
    // figure out if there's a hash in the url;
    if (location.hash) {
      const theHash = location.hash.substring(1);
      for (let i = 0; i < tabs.length; i++) {
        // if hash in url, check if the hash is a valid tab;
        if (theHash === tabs[i].slug || theHash === `tab_${i + 1}`) {
          // console.log("current tab: ", tabs[i]);
          // if so, set curret tab to the tab with the hash
          setCurrentTab(i);
        }
      }
    }
  }, [location]);

  const handleTabClick = (index) => {
    // console.log(location);
    // setCurrentTab(index);
    const thisSlug = tabs[index].slug || `tab_${index + 1}`;
    const thePath = `${location.pathname}#${thisSlug}`;
    // console.log(thePath);
    // push tab slug to current history
    navigate(thePath);
  };

  return (
    <TabSetDiv color={color || "var(--green)"}>
      <nav>
        <ul>
          {tabs.map((thisTab, index) => {
            const thisSlug = thisTab.slug || `tab_${index + 1}`;
            return (
              <li
                className={index === currentTab ? "selected" : ""}
                key={index}
              >
                <H6>
                  <a
                    href={`#${thisSlug}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick(index);
                    }}
                  >
                    {thisTab.title}
                  </a>
                </H6>
              </li>
            );
          })}
        </ul>
      </nav>
      <section>
      {leftColumn!=null ? (
        <div class="pdf_btn_selector">{leftColumn}</div>
      ) : (
        <div>{leftColumn} </div>
      )}
        {tabs[currentTab].content}</section>
      <nav style={{display: 'none'}}>
        <ul>
          {tabs.map((thisTab, index) => {
            const thisSlug = thisTab.slug || `tab_${index + 1}`;
            return (
              <li
                className={index === currentTab ? "selected" : ""}
                key={"bottom_" + index}
              >
                <H6>
                  <a
                    href={`#${thisSlug}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleTabClick(index);
                    }}
                  >
                    {thisTab.title}
                  </a>
                </H6>
              </li>
            );
          })}
        </ul>
      </nav>
    </TabSetDiv>
  );
};

export default Selector;

Selector.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      content: PropTypes.element,
    })
  ),
};
