import styled from "styled-components";

export const FoundationPeopleSelectorDiv = styled.div`
  --bottomColor: ${(props) =>
    props.bottomColor ? props.bottomColor : "var(--white)"};
  --topColor: ${(props) => (props.topColor ? props.topColor : "var(--pink)")};
  & > div:first-of-type {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 40px;
    & .slider {
      display: flex;
      padding-left: var(--outerMargin);
      & > div {
        display: flex;
        flex: 1;
        flex-direction: column;
        width: 295px;
        min-width: 295px;
        & > div {
          width: 295px;
          height: 225px;
          position: relative;
          &:after {
            content: "";
            bottom: 0;
            left: 0;
            right: 0;
            height: 4px;
            opacity: 0;
            transition: 0.5s;
            background-color: var(--purple);
            z-index: 2;
            position: absolute;
          }
          & > div {
            position: relative;
            & img {
              filter: grayscale(var(--value, 100%));
            }
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              top: 0;
              transition: 0.5s;
              background-color: var(--topColor);
              mix-blend-mode: multiply;
            }
          }
        }
        & h5 {
          margin-top: 20px;
        }
        & .placeholder {
          width: 100%;
          height: 100%;
          background-color: var(--gray);
        }
        & + div {
          margin-left: 20px;
        }
        &.selected,
        &:hover {
          cursor: pointer;
          & > div:after {
            opacity: 1;
          }
          & > div > div:after {
            opacity: 0;
          }
          & h5 {
            color: var(--purple);
          }
        }
      }
    }
    & + div {
      background-color: var(--bottomColor);
      min-width: 300vh;
      margin-left: -100vh;
      margin-right: -100vh;
      display: flex;
      justify-content: center;
      & h4 {
        color: var(--purple);
      }
      & > div {
        width: 715px;
        margin-top: 25px;
        margin-bottom: 50px;
      }
    }
  }
  @media (max-width: 767px) {
    & > div:first-of-type + div {
      margin: 0;
      min-width: initial;
      max-width: 100vw;
      padding-left: var(--outerMargin);
      padding-right: var(--outerMargin);
      & > div {
        width: 100%;
      }
    }
  }
`;
